import React from "react";
import { Box, BoxProps, Flex, Text } from "@chakra-ui/react";
import { CheckCircleIcon, WarningIcon } from "@chakra-ui/icons";

/*
  Alert message
  Easier to make our own alerts vs using Chakra Alert composition
  see: https://chakra-ui.com/alert#composition
 */

const FormMessage = ({
  success,
  heading,
  // ruleBottom,
  isContainer,
  children,
  ...props
}: {
  success?: boolean;
  heading: string | React.ReactNode;
  // ruleBottom?: boolean;
  isContainer?: boolean;
  children?: React.ReactNode;
} & BoxProps) => (
  <Box
    // borderTop={ruleBottom ? 0 : "1px solid"}
    // borderBottom={ruleBottom ? "1px solid" : 0}
    // borderColor={success ? "form.good" : "form.bad"}
    bg={success ? "form.goodShade" : "form.badShade"}
    borderRadius="base"
    {...props}
  >
    <Flex
      py={3}
      px={3}
      justify={isContainer && "center"}
      maxW={isContainer && "960px"}
      mx={isContainer && "auto"}
    >
      <Box mr="10px" transform="translateY(-1px)">
        {success ? (
          <CheckCircleIcon color="form.good" />
        ) : (
          <WarningIcon color="form.bad" />
        )}
      </Box>

      <Box mt="2px">
        <Text
          as="div"
          textStyle="textBody"
          fontWeight="600"
          color={success ? "form.good" : "form.bad"}
          transform="translateY(-0.07em)"
        >
          {heading}
        </Text>
        {children && (
          <Text
            as="div"
            textStyle="textRg"
            color={success ? "form.good" : "form.bad"}
            pt="1px"
            sx={{
              "& a": {
                color: "currentColor",
              },
            }}
          >
            {children}
          </Text>
        )}
      </Box>
    </Flex>
  </Box>
);

export default FormMessage;
