/**
 * Map an api error code to an error message
 *
 * Accepts a object map of error codes mapped to error messages,
 * falls back to the servers error message
 */

import { IApiError } from "@/lib/api";

export function mapApiErrorToMessage(
  error: IApiError,
  errorToMessageMap: Record<string, any>
) {
  return errorToMessageMap[error.code] || error.message;
}
