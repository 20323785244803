import parsePhoneNumber from "libphonenumber-js";

/**
 * Returns an error message or null
 */
export default function validatePhoneNumber(value: string): string | null {
  if (value) {
    const number = parsePhoneNumber(value, "US");

    if (!number) {
      return "Invalid phone number";
    }
    if (!number?.countryCallingCode) {
      return "Must include country code";
    }
    if (number?.country !== "US") {
      return "Must be a US number";
    }
    if (!number?.isValid()) {
      return "Invalid phone number";
    }
  }

  return null;
}
