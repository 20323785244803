/**
 * @module schemas
 *
 * Exports yup schemas for form fields
 * Reuse these schemas for consistent validation requirements across the app
 */

// Yup uses curly braces for interpolation in regular strings
/* eslint-disable no-template-curly-in-string */

import * as Yup from "yup";
import * as constants from "../config/constants";
import { EUserGender } from "@/types";
import validatePhoneNumber from "../lib/validatePhoneNumber";

export { Yup };

// Default messages
Yup.setLocale({
  mixed: {
    required: "${label} is required",
  },
});

/**
 * Properties can be overridden e.g. `schemas.lastName.notRequired()`
 */
export const schemas = {
  /**
   *
   * User
   *
   */
  isChildAccount: Yup.boolean(),
  email: Yup.string().label("Email").email("Invalid email address").required(),
  phone: Yup.string().label("Phone").email("Invalid phone number").required(),
  password: Yup.string().label("Password").min(8).required(),
  passwordConfirm: Yup.string()
    .label("Confirm Password")
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required(),
  caregiverFirstName: Yup.string().label("Caregiver First Name").max(35),
  caregiverLastName: Yup.string().label("Caregiver Last Name").max(35),
  firstName: Yup.string().label("First Name").max(35).required(),
  lastName: Yup.string().label("Last Name").max(35).required(),
  consent: Yup.boolean().oneOf(
    [true],
    "You must agree to the terms and conditions to continue"
  ),
  smsConsent: Yup.boolean(),
  dateOfBirth: Yup.string().label("Date of birth").required(),
  address: Yup.string().label("Street Address").required(),
  city: Yup.string().label("City"),
  state: Yup.string()
    .oneOf(Object.values(constants.SUPPORTED_STATES))
    .label("State")
    .required(),
  zipCode: Yup.string()
    .matches(/^[0-9]+$/, "Must be a number")
    .test("len", "Must be exactly 5 characters", (val) => {
      return !!val && val.length === 5;
    })
    .label("Zip Code")
    .required(),
  phoneNumber: Yup.string()
    .label("Phone Number")
    // val.length === 12 because the prefix "+1" is applied to all numbers
    // but is invisible to the user
    .test("len", "Phone Number must be exactly 10 characters", (val) => {
      // Only test if value is set, allows use without phone being required
      // CASE: when registering a kit for yourself
      // Chain .required() to require a value
      if (val) return val.length === 12;
      return true;
    })
    .test({
      name: "validate-phone-number-lib",
      test(value) {
        if (value) {
          const error = validatePhoneNumber(value);
          // Return a custom error message
          if (error)
            return this.createError({ path: this.path, message: error });
        }
        return true;
      },
    }),
  gender: Yup.string()
    .label("Gender")
    .oneOf(Object.values(EUserGender))
    .required(),
  personalGenderPronoun: Yup.string()
    .label("Personal Gender Pronoun")
    .oneOf(Object.keys(constants.GENDER_PRONOUNS))
    .required(),

  /*
   *
   * Allergy Questionaire
   *
   */
  checkboxGroup: Yup.array().of(Yup.string()),
  radioGroup: Yup.string(),

  /*
   *
   * Register Test Kit
   *
   */
  person: Yup.string()
    .label("Person")
    .oneOf(Object.keys(constants.PERSON))
    .required(),
  kitNumber: Yup.string()
    .label("Kit code")
    .required()
    .matches(/^[a-zA-Z0-9]+$/, "Alphanumeric characters only")
    .min(4, "Must be 4-6 characters")
    .max(6, "Must be 4-6 characters"),
  // NOTE WELL: we implicitly assume consent via explicit UI
  // kitConsent: Yup.boolean()
  //   .oneOf([true], "You must agree to the terms and conditions to continue")
  //   .required(),
  // Array of veritas medication configuration ids
  veritasMedications: Yup.array().of(Yup.number()).required(),
};
