// import * as React from "react";
import Link from "next/link";
import { Text } from "@chakra-ui/react";
import DynamicLink from "@/components/LinkDynamic";

export const Message = ({
  inheritColor,
  children,
}: {
  inheritColor?: boolean;
  children: React.ReactNode;
}) => (
  <Text
    layerStyle="formMessage"
    textAlign="center"
    sx={
      inheritColor && {
        "& > *": {
          color: "inherit",
        },
      }
    }
  >
    {children}
  </Text>
);

// SIGN-UP
export const RegisterMessage = ({
  message = "Create yours",
  link = "/register",
}: {
  message?: string;
  link?: string;
}) => (
  <Message>
    {/* Already have an account? <Link href="/login">Sign in</Link>. */}
    No account? <Link href={link}>{message}</Link>
  </Message>
);

// LOGIN
export const LoginMessage = ({
  link = "/login",
  message,
}: {
  link?: string;
  message?: string;
}) => (
  <Message>
    {message}
    <Link href={link}>Sign in</Link>
  </Message>
);

// FORGOT PW
export const ForgotPasswordMessage = ({
  isTargetBlank,
}: {
  isTargetBlank?: boolean;
}) => (
  <Message>
    {isTargetBlank ? (
      <DynamicLink isTargetBlank href="/forgot-password">
        <>Forgot password?</>
      </DynamicLink>
    ) : (
      <Link href="/forgot-password">Forgot password?</Link>
    )}
  </Message>
);
