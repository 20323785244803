import { useFormikContext } from "formik";
import FormMessage from "./FormMessage";
import { Box } from "@chakra-ui/react";
import { ConditionalWrapper } from "./ConditionalWrapper";
import { mapApiErrorToMessage } from "@/lib/mapApiErrorToMessage";

type IApiErrorMessageValue = string | React.ReactNode;

interface IApiErrorMessage {
  showWrapper?: boolean;
  wrapperProps?: any;
  messageMap?: {
    [key: string]:
      | IApiErrorMessageValue
      | {
          primary: IApiErrorMessageValue;
          secondary?: IApiErrorMessageValue;
        };
  };
}

/**
 *
 * FORM API ERROR MESSAGE
 * ------------------------
 *
 * This component is used to display an error message from the API.
 * Falls back to the server's error message or it's own default error message. For this to happen, it must always accept an empty `messageMap` object as default.
 *
 * The `messageMap` prop - Object keys are error codes from the api, the value is what's rendered
 *
 * Example #1:
 * {
 *   EMAIL_UNVERIFIED: "Please verify your email.",
 * }
 *
 * Example #2:
 * {
 *   EMAIL_UNVERIFIED: {
 *       primary: "Please verify your email.",
 *       secondary: (
 *         <>
 *           Please check your email for a verification code. Can&apos;t find
 *           it?&nbsp;
 *           <Link href="/resend-verification-email">Send a new link</Link>
 *         </>
 *       ),
 *     },
 *  }
 */
const FormApiErrorMessage = ({
  showWrapper,
  wrapperProps,
  messageMap = {},
}: IApiErrorMessage) => {
  const formik = useFormikContext();
  const apiError = formik.status && formik.status.apiError;

  if (!apiError) return null;

  const error = mapApiErrorToMessage(apiError, messageMap);
  let heading: IApiErrorMessageValue;
  let children: IApiErrorMessageValue;

  if (typeof error === "string") {
    heading = error;
  } else {
    heading = error.primary;
    children = error.secondary;
  }

  return (
    <ConditionalWrapper
      condition={showWrapper}
      wrapper={(children) => <Box {...wrapperProps}>{children}</Box>}
    >
      <FormMessage mt={0} heading={heading}>
        {children}
      </FormMessage>
    </ConditionalWrapper>
  );
};

export default FormApiErrorMessage;
