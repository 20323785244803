import React from "react";
import { useRouter } from "next/router";
import { useDispatch, useSelector } from "react-redux";
import * as authActions from "@/store/authentication/actions";

/**
 * Is passed null: do nothing
 * Is passed true: ensure authed
 * Is passed false: ensure not authed
 *
 * Accepts null to do nothing since hooks cannot be conditionally called
 * It allows not enforcing a certain auth state if not wanted
 */
export default function useEnsureAuthedIs(
  ensureAuthedIs = null,
  { redirectPath } = {}
) {
  const dispatch = useDispatch();
  const router = useRouter();
  const hasFetchedUser = useSelector(authActions.selectors.getHasFetchedUser);
  const user = useSelector(authActions.selectors.getUser);

  React.useEffect(() => {
    if (!hasFetchedUser) dispatch(authActions.fetchUser());
  }, [dispatch, hasFetchedUser]);

  // Memoize this push function so the below useEffect doesn't run whenever
  // the router.push function changes, only user
  // Otherwise, when using react transition group page transitions,
  // this causes a redirect loop
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const push = React.useCallback((path) => router.push(path), []);
  React.useEffect(() => {
    if (ensureAuthedIs === null) return;
    if (ensureAuthedIs === false && user) push(redirectPath || "/");
    if (ensureAuthedIs === true && !user) push(redirectPath || "/login");
    // With transisions the page and store state changes before the page
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasFetchedUser]);
}
