import { Box } from "@chakra-ui/react";

const DynamicLink = ({
  isDisabled,
  isTargetBlank,
  children,
  ...props
}: {
  isDisabled?: boolean;
  isTargetBlank?: boolean;
  children: React.ReactElement<any, any>;
  [x: string]: any;
}) => {
  // const { isDisabled, children, ...rest } = props;
  if (isDisabled) return children;
  return (
    <Box
      as="a"
      _hover={{ textDecoration: "none" }}
      target={isTargetBlank ? "_blank" : null}
      {...props}
    >
      {children}
    </Box>
  );
};

export default DynamicLink;
