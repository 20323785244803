import React from "react";
import { useRouter } from "next/router";
import { useDispatch } from "react-redux";
import Link from "next/link";
import { Yup, schemas } from "@/lib/schemas";
import { Formik, Form } from "formik";
import { SimpleGrid, Button } from "@chakra-ui/react";
import Input from "@/components/Form/Input";
import FormApiErrorMessage from "@/components/FormApiErrorMessage";
import * as authActions from "@/store/authentication/actions";
import * as fbpx from "@/lib/fbpx";
import * as gtm from "@/lib/gtm";

export default function LoginForm({
  successPath = "/",
}: {
  successPath?: string;
}) {
  const dispatch = useDispatch();
  const router = useRouter();
  const apiErrorMessageMap = {
    EMAIL_UNVERIFIED: {
      primary: "Please verify your email.",
      secondary: (
        <>
          Please check your email for a verification code. Can&apos;t find
          it?&nbsp;
          <Link href="/resend-verification-email">Send a new link</Link>
        </>
      ),
    },
    INVALID_CREDENTIALS: {
      primary: "Incorrect email or password",
    },
  };

  const onSubmit = async (values, { setStatus }) => {
    setStatus({
      apiError: null,
    });
    const response = await dispatch(
      authActions.loginUser({
        email: values.email,
        password: values.password,
      })
    );

    if (response.error) {
      setStatus({
        apiError: response.error,
      });
      return;
    }

    fbpx.event({
      action: "Login",
      name: "Login",
      category: "Login",
    });

    await router.push(successPath);
  };

  return (
    <>
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        validationSchema={Yup.object({
          email: schemas.email,
          password: schemas.password,
        })}
        onSubmit={onSubmit}
      >
        {(formik) => {
          return (
            <Form className="FormikForm">
              <SimpleGrid spacing={4}>
                {/* @ts-ignore */}
                <Input
                  // variant="profile"
                  label="Email"
                  name="email"
                  type="email"
                  placeholder="Type your email"
                  required
                />
                {/* @ts-ignore */}
                <Input
                  // variant="profile"
                  label="Password"
                  name="password"
                  type="password"
                  placeholder="Create a password"
                  required
                />
                <Button
                  type="submit"
                  isDisabled={!(formik.dirty && formik.isValid)}
                  isLoading={formik.isSubmitting}
                  loadingText="Submitting"
                  minW="full"
                  id="login"
                >
                  Continue
                </Button>
                <FormApiErrorMessage messageMap={apiErrorMessageMap} />
              </SimpleGrid>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}
