import { Box, useDisclosure } from "@chakra-ui/react";
import LayoutBase from "@/components/Layout/LayoutBase";
// import NavbarTop from "@/components/Nav/NavbarTop";
import { NavbarTop } from "@/components/Nav";
import useEnsureAuthedIs from "@/hooks/useEnsureAuthedIs";

export default function LayoutDefault({
  ensureNotAuthed = true,
  redirectPath = null,
  showCentered = true,
  showAsideBg = false,
  children,
}: {
  ensureNotAuthed?: boolean;
  redirectPath?: string;
  showCentered?: boolean;
  showAsideBg?: boolean;
  children: React.ReactNode;
}) {
  useEnsureAuthedIs(ensureNotAuthed ? false : null, {
    redirectPath,
  });
  const { isOpen, onToggle } = useDisclosure();

  return (
    <LayoutBase>
      <NavbarTop
        isMobileMenuOpen={isOpen}
        onMobileMenuToggle={onToggle}
        showAsideBg={showAsideBg}
        showLogoCentered={showCentered}
      />
      <Box as="main" layerStyle="spaceUnderNav">
        {children}
      </Box>
      {/* <Footer noCta={noCta} /> */}
    </LayoutBase>
  );
}
